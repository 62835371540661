import {
	ILeadTarefaResponsavelCleanDTO,
	ILeadTarefaResponsavelDTO,
	ILeadTarefaResponsavelFullDTO
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindList } from './base/base-find-list.service';

export const useLeadTarefaResponsavelService = () => {
	const resourceURL = 'crm/lead-tarefa-responsavel';

	return {
		...baseCreate<ILeadTarefaResponsavelCleanDTO, ILeadTarefaResponsavelDTO, ILeadTarefaResponsavelFullDTO>(resourceURL),
		...baseFindList<ILeadTarefaResponsavelCleanDTO, ILeadTarefaResponsavelDTO, ILeadTarefaResponsavelFullDTO>(resourceURL),
	};
};
