import {
  ICrmLeadTipoCleanDTO,
  ICrmLeadTipoDTO,
  ICrmLeadTipoFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadTipoService = () => {
	const resourceURL = 'crm/lead-tipo';

	return {
		...baseFindList<ICrmLeadTipoCleanDTO, ICrmLeadTipoDTO, ICrmLeadTipoFullDTO>(resourceURL),
	};
};
