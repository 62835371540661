import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
	ILeadEmailCleanDTO,
	ILeadEmailCustomDTO,
	ILeadEmailDTO,
	ILeadEmailFullDTO
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useEmailService = () => {
	const resourceURL = '/crm/lead-email';

	const enviarEmail = (dto: ILeadEmailCustomDTO): Promise<AxiosResponse<ILeadEmailCustomDTO>> => {
		return http.post(`${resourceURL}/enviar-email`, dto);
	}

	return {
		...baseFind<ILeadEmailCleanDTO, ILeadEmailDTO, ILeadEmailFullDTO>(resourceURL),
		...baseFindList<ILeadEmailCleanDTO, ILeadEmailDTO, ILeadEmailFullDTO>(resourceURL),
		...baseUpdate<ILeadEmailCleanDTO, ILeadEmailDTO, ILeadEmailFullDTO>(resourceURL),
		...baseCreate<ILeadEmailCleanDTO, ILeadEmailDTO, ILeadEmailFullDTO>(resourceURL),
		...baseFindById<ILeadEmailCleanDTO, ILeadEmailDTO, ILeadEmailFullDTO>(resourceURL),
		enviarEmail,
	};
};