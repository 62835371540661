import {
	ICrmLeadMotivoPerdaCleanDTO,
	ICrmLeadMotivoPerdaDTO,
	ICrmLeadMotivoPerdaFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadMotivoPerdaService = () => {
	const resourceURL = '/crm/lead-motivo-perda';

	return {
		...baseFindList<ICrmLeadMotivoPerdaCleanDTO, ICrmLeadMotivoPerdaDTO, ICrmLeadMotivoPerdaFullDTO>(resourceURL),
	};
};
