import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { InfoOutlined } from '@mui/icons-material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SendIcon from '@mui/icons-material/Send';
import Switch from '@mui/material/Switch';
import { Button, Input, notification, Select } from "antd";
import { useEffect, useMemo, useState } from 'react';
import { useGlobalContext } from '../../context/global.service';
import { ILeadContatoCleanDTO, ILeadEmailCustomDTO } from '../../models/happy-code-api.model';
import { useEmailService } from '../../services/lead-email';
import { useLeadContext } from "../../context/lead.context.service";
import { useLeadContatoCampanhaService } from "../../services/lead-contato.service";
import { Predicate } from "../../models/predicate.model";
import { validateEmail } from "../../util/format";

export const EmailLead = () => {
    const { setIsGlobalLoading } = useGlobalContext();
    const { leadSelecionado } = useLeadContext();
    const emailService = useEmailService();
    const [email, setEmail] = useState<ILeadEmailCustomDTO>();
    const [leadContato, setLeadContato] = useState<ILeadContatoCleanDTO[]>();
    const useLeadContato = useLeadContatoCampanhaService();


    const sendEmail = () => {
        setIsGlobalLoading(true);

        emailService.enviarEmail(email)
            .then((response) => {
                setEmail(response.data);
                notification.success({ description: `E-mail enviado com sucesso, destinatário: ${email?.dsDestinatario}`, type: 'success', message: 'Sucesso!', placement: "bottomRight" });
                setTimeout(() => {
                    location.reload();
                }, 500);
            })
            .finally(() => setIsGlobalLoading(false));
    };


    const getAllContatoLead = () => {
        setIsGlobalLoading(true);

        const predicate = new Predicate();
        predicate.addOption('lead.id', leadSelecionado.id)
        predicate.addOption('ativo', true)
        useLeadContato.findCleanList(predicate)
            .then((response) => {
                setLeadContato(response.data);
            })
            .finally(() => setIsGlobalLoading(false))
    }

    const submitValidationFields = () => {
        if (!email?.dsRemetente) {
            notification.warning({
                description: `E-mail do remetente dever ser informado.`,
                type: 'warning',
                message: 'Atenção!',
                placement: "bottomRight"
            });
            return;
        }
        if (!email?.dsDestinatario) {
            notification.warning({
                description: `E-mail do destinatário dever ser informado.`,
                type: 'warning',
                message: 'Atenção!',
                placement: "bottomRight"
            });
            return;
        }
        if (!email?.dsAssunto) {
            notification.warning({
                description: `O assunto deve ser informado.`,
                type: 'warning',
                message: 'Atenção!',
                placement: "bottomRight"
            });
            return;
        }
        if (!email?.dsEmail) {
            notification.warning({
                description: `O corpo do e-mail deve ser preenchido.`,
                type: 'warning',
                message: 'Atenção!',
                placement: "bottomRight"
            });
            return;
        }

        sendEmail();
    }

    const emailValido = useMemo(() => {
        if (email?.dsCc === null || email?.dsCc === undefined) return false;

        return validateEmail(email?.dsCc);
    }, [email?.dsCc])


    useEffect(() => {
        setEmail({ ...email, idLead: leadSelecionado?.id, dsRemetente: "nao-responder@happy.com.br" });
        getAllContatoLead()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository")
            .createUploadAdapter = (loader) => {
                return uploadAdapter(loader);
            };
    }

    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve) => {
                    loader.file.then((file) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            const base64String = reader.result?.toString().split(',')[1] || '';
                            resolve({ default: `data:image/png;base64,${base64String}` });
                        };
                        reader.readAsDataURL(file);
                    });
                });
            },
        };
    }

    const handleChangeEmail = (value: string) => {
        setEmail({ ...email, dsDestinatario: value })
    };

    return (
        <>
            <div className="flex justify-between gap-20 mb-20">
                <div className="flex align-center gap-10">
                    <MailOutlineIcon />
                    <h1 id="sem-margin">Novo e-mail</h1>
                </div>
                <div className="flex gap-10">

                    {/* <Upload
            onChange={handleFile}>
            <Button className="button-line" icon={<AttachFileIcon style={{ color: 'var(--azul-happy)' }} />}>Anexar arquivo</Button>
          </Upload> */}

                    {/* <input
            type='file'
            onChange={(e) => setFile(e.target.files[0] || null)} /> */}

                    <Button
                        onClick={submitValidationFields}
                        className="button-bronce"
                        icon={<SendIcon style={{ color: 'var(--white)' }} />}
                    >
                        Enviar
                    </Button>
                </div>
            </div>
            <div className="box-inputs flex flex-wrap gap-20">
                <div className="flex align-center gap-10 w-50">
                    <label htmlFor="">De</label>
                    <div className="box-white w-100">
                        <Input
                            placeholder="nao-responder@happy.com.br"
                            disabled
                            className="box-input-email" />
                    </div>
                </div>
                <div className="flex align-center gap-10 flex-fill">
                    <label htmlFor="">CC</label>
                    <div className="box-white w-100 align-center">
                        <Input
                            placeholder="E-mail para Cópia"
                            className="box-input-email"
                            onChange={(e) => setEmail({ ...email, dsCc: e.target.value })}
                        />
                        {!emailValido && email?.dsCc?.length >= 0 && <small>Digite um e-mail válido</small>}
                    </div>
                </div>
                <div className="flex align-center gap-10 w-50">
                    <label htmlFor="">Para</label>
                    <div className="box-white w-100 flex align-center" style={{ border: "1.5px solid #d9d9d9", height: 60, borderRadius: 6 }}>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Selecione um E-mail"
                            onChange={handleChangeEmail}
                        >
                            {leadContato && leadContato?.map((contato: ILeadContatoCleanDTO) => (
                                <Select.Option key={contato.id} text={contato.dsEmail} value={contato.dsEmail}>{contato.dsEmail}</Select.Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="flex align-center gap-10 flex-fill">
                    <label htmlFor="">Assunto*</label>
                    <div className="box-white w-100">
                        <Input
                            placeholder="Escreva o assunto do e-mail"
                            className="box-input-email"
                            onChange={(e) => setEmail({ ...email, dsAssunto: e.target.value })} />
                    </div>
                </div>
            </div>
            {/* TextArea */}
            <div className="flex flex-start gap-20">
                <Switch defaultChecked sx={{ color: 'var(--cinza-azulado-500)', marginTop: -1 }} />
                <h6>Criar tarefa quando o e-mail for lido pelo cliente</h6>
                <InfoOutlined sx={{ color: 'var(--cinza-azulado-500)' }} />
            </div>

            <div className="input-box" style={{ height: "100%", marginTop: 10 }}>
                <CKEditor
                    config={{
                        extraPlugins: [uploadPlugin],
                        removePlugins: ['MediaEmbed']
                    }}
                    editor={ClassicEditor}
                    data={""}
                    onReady={(editor) => {
                        // You can do something when the editor is ready
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setEmail({ ...email, dsEmail: data })
                    }}
                />
            </div>
        </>
    )
}
