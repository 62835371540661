import {
	ICurriculoCleanDTO,
	ICurriculoDTO,
	ICurriculoFullDTO,
} from '../models/happy-code-api.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useCurriculoService = () => {
	const resourceURL = 'curriculo';

	return {
		...baseFind<ICurriculoCleanDTO, ICurriculoDTO, ICurriculoFullDTO>(resourceURL),
		...baseFindList<ICurriculoCleanDTO, ICurriculoDTO, ICurriculoFullDTO>(resourceURL),
		...baseFindById<ICurriculoCleanDTO, ICurriculoDTO, ICurriculoFullDTO>(resourceURL),
	};
};
