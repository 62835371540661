import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Cargos } from './pages/Cargos';
import { Dashboard } from './pages/Dashboard';
import { Equipes } from './pages/Equipes';
import { Home } from './pages/Home/';
import { DetalheLead } from './pages/Leads';
import { Tarefas } from "./pages/Tarefas";
import { Usuarios } from './pages/Usuarios';
import Login from "./pages/Home/login";

export const Rotas = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/acesso/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/tarefas" element={<Tarefas />} />
        <Route path="/cargos" element={<Cargos />} />
        <Route path="/usuarios" element={<Usuarios />} />
        <Route path="/equipes" element={<Equipes />} />
        <Route path="/lead/:leadId" element={<DetalheLead />} />
        <Route path="/lead/:leadId/tarefa" element={<DetalheLead />} />
      </Routes>
    </Router>
  );
}
