import { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { Header } from "../../components/Header";
import { Funil } from "./funil";
import { List } from "./list";
import { ModalLeadNew } from "../../components/Modal/leadnew";
import { useGlobalContext } from "../../context/global.service";
import { useLeadContext } from "../../context/lead.context.service";

export const Home = () => {

  const [isOpenLead, setIsOpenLead] = useState(false);
  const { funilView, setFunilView, refresh, setFlModoEdicaoTarefa } = useLeadContext();
  const [styleButtons, setStyleButtons] = useState('button-bronce');

  useEffect(() => {
    refresh();
    setFlModoEdicaoTarefa(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseLead = () => { setIsOpenLead(false) }

  function handleFunil(event: any) {
    setFunilView(true);
    setStyleButtons('button-bronce');
  }

  function handleList(event: any) {
    setFunilView(false);
    setStyleButtons('button-blue')
  }

  return (
    <>
      <div className="container-happy">
        <Sidebar crm={true} />
        <div className="dashboard">
          <Header filtros={true}
            modal="lead"
            onFunil={handleFunil}
            onList={handleList}
            estilo={styleButtons}
          />
          {funilView === true ?
            <Funil />
            :
            <List />
          }
        </div>
      </div>
      <ModalLeadNew
        open={isOpenLead}
        onClose={onCloseLead}
      />
    </>
  );
}
