import http from '../helpers/http-common';

import {
  ICrmLeadInteresseCleanDTO,
  ICrmLeadInteresseDTO,
  ICrmLeadInteresseFullDTO
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindList } from './base/base-find-list.service';
import { baseUpdate } from './base/base-update.service';

export const useLeadInteresseService = () => {
	const resourceURL = 'crm/lead-interesse';

	const getUltimoInteresseDoLead = (idLead: number) => {
		return http.get(`${resourceURL}/${idLead}/ultimo`);
	}

	return {
		...baseCreate<ICrmLeadInteresseCleanDTO, ICrmLeadInteresseDTO, ICrmLeadInteresseFullDTO>(resourceURL),
		...baseUpdate<ICrmLeadInteresseCleanDTO, ICrmLeadInteresseDTO, ICrmLeadInteresseFullDTO>(resourceURL),
		...baseFindList<ICrmLeadInteresseCleanDTO, ICrmLeadInteresseDTO, ICrmLeadInteresseFullDTO>(resourceURL),
		getUltimoInteresseDoLead,
	};
};
