import { Button, Select, notification } from 'antd';
import { useState } from "react";
import * as XLSX from "xlsx";
import { ModalResposta } from '../../components/Modal/resposta';
import { useGlobalContext } from "../../context/global.service";
import { useLeadContext } from "../../context/lead.context.service";
import { useLeadService } from "../../services/lead.service";
import { PlanilhaLeads } from '../PlanilhaLeads';


interface Props {
    open: boolean;
    onClose: () => void;
}

export type ILeadProps = {
    idUnidade: number;
    NOME: string,
    EMAIL: string,
    TELEFONE: number,
    DDD: number,
    QUALIFICACAO: string,
    ETAPA_FUNIL: string,
    CAMPANHA_3K: string,
    STATUS?: string,
}

export const ModalAddLeadNew = (props: Props) => {
    const { open, onClose } = props;
    const { setIsGlobalLoading } = useGlobalContext();
    const { unidade, refresh } = useLeadContext();
    const [sucessoLead, setSucessoLead] = useState(false);

    const { adicionarListaLead } = useLeadService();

    const [planilhaLead, setPlanilhaLead] = useState<ILeadProps[] | unknown[]>();

    const handleFileUpload = (e: any | null) => {
        const reader = new FileReader();
        reader.readAsBinaryString(e.target.files[0]);
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const parsedData = XLSX.utils.sheet_to_json(sheet);
            setPlanilhaLead(parsedData);
        };
    }

    const leadOk = []

    if (planilhaLead) {
        planilhaLead.map((lead: ILeadProps) => {
            lead['dsNomeLead'] = lead['NOME'];
            lead['cdEmail'] = lead['EMAIL'];
            lead['cdCelular'] = lead['TELEFONE'];
            lead['qualificacao'] = lead['QUALIFICACAO'];
            lead['etapaFunil'] = lead['ETAPA_FUNIL'];
            lead['flProjeto3k'] = lead['CAMPANHA_3K'];

            const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

            const validarEmail = regex.test(lead.EMAIL);

            const telefone = lead.TELEFONE != null && lead.TELEFONE.toString().length == 11; 

            const campanha_3k = lead.CAMPANHA_3K != null;

            const etapa = lead.ETAPA_FUNIL != null;

            const qualificacao = lead.QUALIFICACAO != null;

            const nome = lead.NOME != null;

            if (validarEmail == true && campanha_3k == true && etapa == true && qualificacao == true && telefone == true && nome == true) {
                lead.STATUS = "OK";
                lead.idUnidade = unidade.id;
            } else {
                lead.STATUS = "ERROR"
            }
        });
        const filterLead = planilhaLead.filter((lead: ILeadProps) => {
            if (lead.STATUS == "OK") {
                leadOk.push(lead);
            }
        });

    }

    const [isOpen, setIsOpen] = useState(false);

    const onSalvarClick = () => {
        setIsGlobalLoading(true);

        return adicionarListaLead(leadOk).then((response) => {
            if (response?.status >= 200 && response?.status <= 299) {
                return refresh();
            }
            throw new Error('Falha ao cadastrar o Planilha lead.');
        }).then((_) => {
            notification.success({ description: 'Planilha de Leads cadastrado com sucesso!', type: 'success', message: 'Sucesso!', placement: 'bottomRight' });
            setSucessoLead(true);
        }).finally(() => {
            setIsGlobalLoading(false)
        })

    }

    const onClose1 = () => {
        setIsOpen(false)
    }

    const submitValidationFields = () => {
        if (planilhaLead == null) {
            return notification.warning({
                description: `É necessário informar uma Planilha para a adicionar as Leads.`,
                type: 'warning',
                message: 'Atenção!',
                placement: 'bottomRight'
            });
        }

        if(planilhaLead.length == 0){
            return notification.warning({
                description: `É necessário informar uma Planilha com Leads para a adicionar as Leads.`,
                type: 'warning',
                message: 'Atenção!',
                placement: 'bottomRight'
            });
        }

        if(leadOk.length == 0){
            return notification.warning({
                description: `Sua Planilha de leads não contém nenhuma lead Ok, por favor selecione outra planilha.`,
                type: 'warning',
                message: 'Atenção!',
                placement: 'bottomRight'
            });
        }
        onSalvarClick();
    }

    if (!open) return null

    return (
        <>
            {
                sucessoLead == false ?
                    <div className="flex flex-row justify-center align-center modal-container">
                        <div className="flex flex-column justify-start align-start modal-planilha-leads">
                            <Button onClick={onClose} className="button-x">x</Button>
                            <h3 >Cadastrar Planilhas de Leads</h3>

                            <div className="flex flex-row justify-between w-100" style={{ marginBottom: 10 }}>
                                <span>Deseja a baixar Planilha Padrão</span>
                                <Button>
                                    <a href='https://happycodefiles.blob.core.windows.net/public/planilha_lead.xlsx' download>
                                        Download Planilha Padrão
                                    </a>
                                </Button>
                            </div>

                            <div className="flex flex-row justify-between w-100" style={{ marginBottom: 10 }}>
                                <span>Selecione a Planilha de Leads</span>
                                <div className="formWrapper">
                                    <div className="upload">
                                        <div className="inputFileOverlay">{planilhaLead == null ? "Selecione o arquivo" : "Planilha Selecionada"}</div>
                                        <input className='w-30 input-file'
                                            type='file'
                                            accept='.xlsx, .xls'
                                            onChange={handleFileUpload}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex w-100 flex-column" style={{ marginBottom: 10 }}>
                                {planilhaLead != null && (
                                    <PlanilhaLeads data={planilhaLead}/>
                                )}
                            </div>

                            <div className="flex flex-row justify-end w-100">
                                <Button onClick={onClose} className="button-blue mr-5">Cancelar</Button>
                                <Button onClick={submitValidationFields} className="button-primary">Salvar</Button>
                            </div>
                        </div>
                        <ModalResposta
                            open={isOpen}
                            onClose={onClose1}
                            status="Sucesso!"
                            text="As informações do Lead foram salvas com sucesso."
                            link=""
                        />
                    </div>
                    :
                    <div className="flex flex-row justify-center align-center modal-container">
                        <div className="flex flex-column justify-start align-start modal-successo-leads">
                            <Button onClick={onClose} className="button-x">x</Button>
                            <div className="flex flex-column justify-between w-100" style={{ marginBottom: 10, gap: 10 }}>
                                <span>
                                    Importação de Leads Feitas com Sucesso!
                                </span>
                                <span>
                                    Você importou {leadOk.length} leads!
                                </span>
                            </div>

                            <div className="flex flex-row justify-end align-end w-100" style={{ height: 150 }}>
                                <Button onClick={onClose} className="button-primary">OK</Button>
                            </div>
                        </div>
                        <ModalResposta
                            open={isOpen}
                            onClose={onClose1}
                            status="Sucesso!"
                            text="As informações do Lead foram salvas com sucesso."
                            link=""
                        />
                    </div>
            }
        </>
    )
}
