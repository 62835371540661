import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LaptopIcon from '@mui/icons-material/Laptop';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PersonIcon from '@mui/icons-material/Person';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import type { RadioChangeEvent } from 'antd';
import { Button, Input, Radio, Select } from "antd";
import { format, parse } from 'date-fns';
import { useEffect, useMemo, useState } from "react";
import InputMask from 'react-input-mask';
import { useGlobalContext } from "../../context/global.service";
import { useLeadContext } from '../../context/lead.context.service';
import { ICrmLeadTarefaCleanDTO, ICrmLeadTarefaCustomCleanDTO, ILeadTarefaTipoCleanDTO, IUsuarioCleanDTO } from "../../models/happy-code-api.model";
import { Predicate } from '../../models/predicate.model';
import { useLeadTarefaResponsavelService } from '../../services/lead-tarefa-responsavel.service';
import { useLeadTarefaService } from '../../services/lead-tarefa.service';
import { useNotificationService } from '../../services/notification.service';
import TextArea from 'antd/es/input/TextArea';

export const TarefaLead = (props: any) => {

  const [value, setValue] = useState(1);
  const { Option } = Select;
  const azul = '#1677ff';

  const {
    state,
    leadSelecionado,
    fetchHistoricos,
    flModoEdicaoTarefa,
    setFlModoEdicaoTarefa,
    refreshLeadSelecionado,
  } = useLeadContext();

  const {
    setIsGlobalLoading,

  } = useGlobalContext();

  const leadTarefaService = useLeadTarefaService();
  const leadTarefaResponsavelService = useLeadTarefaResponsavelService();

  const notification = useNotificationService();

  const [dsAssunto, setDsAssunto] = useState<string>();
  const [dsTarefa, setDsTarefa] = useState<string>();
  const [dataTarefa, setDataTarefa] = useState<string>();
  const [horaTarefa, setHoraTarefa] = useState<string>();
  const [responsaveis, setResponsaveis] = useState<string>();
  const [tarefa, setTarefa] = useState<ICrmLeadTarefaCustomCleanDTO>(props?.tarefa);

  useEffect(() => {
    if (!tarefa?.id) return;

    const predicate = new Predicate();
    predicate.addOption('ativo', true);
    predicate.addOption('leadTarefa.id', tarefa.id);

    setIsGlobalLoading(true);
    leadTarefaResponsavelService.findFullList(predicate).then((response) => {
      setResponsaveis(response.data?.map?.((item) => item.responsavel?.login)?.[0]);
    }).catch((e) => {
      setResponsaveis(null);
    }).finally(() => setIsGlobalLoading(false))
    setDsAssunto(tarefa.dsAssunto);
    setDsTarefa(tarefa.dsTarefa);
    setDataTarefa(format(new Date(tarefa.dhTarefa), 'dd/MM/yyyy',));
    setHoraTarefa(format(new Date(tarefa.hrTarefa), 'HH:mm',));
    setValue(tarefa.idTipoTarefa);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tarefa])

  const handleChange = (value: string) => {
    if (value?.length <= 0) return;

    setResponsaveis(value);
  };

  const tarefaTipoSelecionada: ICrmLeadTarefaCleanDTO = useMemo(() => {
    return state?.tarefaTipos?.find?.((item: ILeadTarefaTipoCleanDTO) => item.id === value) || null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const [icones, _] = useState(
    {
      'WhatsAppIcon': WhatsAppIcon,
      'EmailOutlinedIcon': EmailOutlinedIcon,
      'SupervisedUserCircleIcon': SupervisedUserCircleIcon,
      'LaptopIcon': LaptopIcon,
      'LocationCityIcon': LocationCityIcon,
      'SettingsIcon': SettingsIcon,
      'ScreenShareOutlinedIcon': ScreenShareOutlinedIcon,
    }
  )

  const onCriarTarefaPress = () => {
    if (!tarefaTipoSelecionada || !leadSelecionado) {
      notification({ description: 'Selecione a categoria da tarefa', type: 'warning', message: 'Atenção!' });
      return;
    }

    if (!responsaveis || responsaveis?.length <= 0) {
      notification({ description: 'Selecione um responsável', type: 'warning', message: 'Atenção!' });
      return;
    }

    if (!dsAssunto || dsAssunto?.length <= 0) {
      notification({ description: 'Informe o assunto', type: 'warning', message: 'Atenção!' });
      return;
    }


    if (!dsTarefa || dsTarefa?.length <= 0) {
      notification({ description: 'Informe uma descrição', type: 'warning', message: 'Atenção!' });
      return;
    }

    if (!horaTarefa || horaTarefa?.length <= 4) {
      notification({ description: 'Informe a hora', type: 'warning', message: 'Atenção!' });
      return;
    }

    if (!dataTarefa || dataTarefa?.length <= 9) {
      notification({ description: 'Informe a data', type: 'warning', message: 'Atenção!' });
      return;
    }

    // const responsaveisTemp = responsaveis?.map((emailResponsavel: string) => {
    // setResponsaveis(//   return state?.usuarios?.find?.((item: IUsuarioCleanDTO) => item.login );=== emailResponsavel);
    // }).map((item: IUsuarioCleanDTO) => {
    //   return {
    //     responsavel: {
    //       id: item.id,
    //     },
    //   };
    // })

    const responsaveisTemp = state?.usuarios?.find?.((item: IUsuarioCleanDTO) => item.login === responsaveis);
    const dataAtual = new Date();
    dataAtual.setUTCHours(parseInt(horaTarefa?.split(':')[0]))
    dataAtual.setUTCMinutes(parseInt(horaTarefa?.split(':')[1]))

    const data = () => {
      const dataSelecionada = dataTarefa.split('/');
      const dia = dataSelecionada[0];
      const mes = dataSelecionada[1];
      const ano = dataSelecionada[2];
      const dataTarefaFormatada = `${ano}-${mes}-${dia}`;
      const dataFinal = new Date(dataTarefaFormatada);
      dataFinal.setUTCHours(dataAtual.getUTCHours());
      dataFinal.setUTCMinutes(dataAtual.getUTCMinutes());

      return dataFinal;
    };

    setIsGlobalLoading(true);

    const body = {
      ...props?.tarefa,
      dsAssunto,
      dsTarefa,
      dhTarefa: data(),
      hrTarefa: dataAtual,
      leadTarefaTipo: {
        id: tarefaTipoSelecionada.id,
      },
      responsaveis: [
        {
          responsavel: {
            id: responsaveisTemp.id,
          },
        }
      ],
      lead: {
        id: leadSelecionado.id
      },
    }

    let request = leadTarefaService.createFull;
    if (body.id) {
      request = leadTarefaService.patchFullCustom;
    }


    request(body).then((response) => {
      if (response.status >= 200 && response.status <= 299) {
        refreshLeadSelecionado();
        fetchHistoricos();
        setDsAssunto(null);
        setDsTarefa(null);
        setValue(null);
        setDataTarefa(null);
        setHoraTarefa(null);
        setResponsaveis(null);

        const mensagem = tarefa?.id ? 'Tarefa atualizada' : 'Tarefa cadastrada';

        if (tarefa?.id) {
          setFlModoEdicaoTarefa(false);
          props?.onClose();
        }

        notification({ description: mensagem, type: 'success', message: 'Sucesso!' });
        return;
      }

      notification({ description: 'Ocorreu um erro ao cadastrar a tarefa.', type: 'error', message: 'Falha' });
    }).finally(() => setIsGlobalLoading(false));
  }

  const onCancelarPress = () => {
    setTarefa(null);
    setFlModoEdicaoTarefa(false);
  }

  return (
    <>
      <div className="flex justify-between gap-20 mb-20">
        <div className="flex align-center gap-10">
          <CalendarTodayIcon />
          <h1 id="sem-margin">{(flModoEdicaoTarefa || props?.modal) ? 'Editar tarefa' : 'Criar tarefa'}</h1>
        </div>
        <div className="align-end flex flex-row">
          {(flModoEdicaoTarefa || props?.modal) && <Button className="button-blue mr-10" onClick={props?.onCancel || onCancelarPress}>
            {'Cancelar'}
          </Button>}
          <Button className="button-bronce" onClick={onCriarTarefaPress}>
            {(flModoEdicaoTarefa || props?.modal) ? 'Editar tarefa' : 'Criar tarefa'}
          </Button>
        </div>
      </div>
      <div className="box-inputs flex flex-wrap gap-20">
        <div>
          <h6>Tipo de Tarefa</h6>
          <div className="mt-20 mb-10">
            <Radio.Group onChange={onChange} value={value}>

              {state?.tarefaTipos?.map?.((item: ILeadTarefaTipoCleanDTO) => {
                const Icon = icones[item.cdIconeLeadTarefaTipo];
                return <Radio key={item?.id} value={item?.id} checked={(tarefa?.idTipoTarefa || tarefaTipoSelecionada?.id) === item.id} className="radio-tarefa">
                  <div className="flex align-center gap-10">
                    <Icon sx={{ color: value === state.id ? azul : '' }} />
                    <span style={{ color: value === state.id ? azul : '' }}>{item.dsLeadTarefaTipo}</span>
                  </div>
                </Radio>
              })}
            </Radio.Group>
          </div>
        </div>
        <div className="flex align-center gap-10 w-100">
          <label htmlFor="" className="w-20">Assunto</label>
          <div className="box-white flex gap-10 flex-fill">
            <Input
              placeholder="Digite o assunto"
              className="box-input-email flex-fill"
              value={dsAssunto}
              style={{ flex: 3 }}
              onChange={(e) => setDsAssunto(e.target.value)} />

            <InputMask
              mask='99/99/9999'
              className="box-input-email flex-fill"
              value={dataTarefa ?? ""}
              onChange={(e) => {
                setDataTarefa(e.target.value)
              }}
            >
              {(inputProps) => <Input {...inputProps} className="box-input-email flex-fill" placeholder='dd/mm/aaaa' bordered={true} />}
            </InputMask>

            <InputMask
              mask='99:99'
              className="box-input-email flex-fill"
              value={horaTarefa ?? ""}
              onChange={(e) => {
                setHoraTarefa(e.target.value)
              }}
            >
              {(inputProps) => <Input {...inputProps} className="box-input-email flex-fill" placeholder='HH:MM' bordered={true} />}
            </InputMask>

          </div>
        </div>


        {/* <div className="box-white flex gap-10 flex-fill">
            <InputMask
              mask='99/99/9999'
              value={dataTarefa ?? ""}
              onChange={(e) => {
                setDataTarefa(e.target.value)
              }}
            >
              {(inputProps) => <Input {...inputProps} placeholder='dd/mm/aaaa' bordered={false} />}
            </InputMask> */}


        <div className="flex align-center gap-10 w-100">
          <label htmlFor="" className="w-20">Descrição</label>
          <div className="box-white flex gap-10 flex-fill">
            <TextArea
              maxLength={200}
              rows={3}
              placeholder="Descrição da tarefa"
              className="box-input-email flex-fill"
              value={dsTarefa} onChange={(e) => setDsTarefa(e.target.value)} />
            {/* <p>{dsTarefa?.length || 0}/200</p> */}
          </div>
        </div>
        {/* <div className="flex align-center gap-10 w-100">
          <label htmlFor="" className="w-20">Responsáveis</label>
          <div className="box-white flex gap-10 flex-fill">
            {/* <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder="Please select"
              defaultValue={['a10', 'c12']}
              onChange={handleChange}
              options={opcoesUsuario}
            />
          </div>
        </div> */}
        <div className="flex align-center gap-20 flex-fill">
          <label htmlFor="" className="w-20 mr-30">Responsáveis</label>
          <div className="box-white w-100 h-50 border-box">
            <Select
              style={{ width: '100%' }}
              placeholder="Selecione o(s) responsável(eis)"
              defaultValue={null}
              onChange={handleChange}
              value={responsaveis}
              optionLabelProp="label"
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option && option?.text?.toLowerCase().includes(input.toLowerCase())}
            >
              {state?.usuarios?.map?.((usuario: IUsuarioCleanDTO) => {
                return <Option key={usuario.id} value={usuario?.login} text={usuario?.login}>
                  <div className="flex align-center">
                    <div className="circle-cinza">
                      <PersonIcon sx={{ color: 'var(--cinza-azulado-200)' }} />
                    </div>
                    {usuario?.login}
                  </div>
                </Option>
              })}
            </Select>
          </div>
        </div>
      </div>
    </>
  )
}
