import {
  ICrmLeadCampanhaCleanDTO,
  ICrmLeadCampanhaDTO,
  ICrmLeadCampanhaFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadCampanhaService = () => {
	const resourceURL = 'crm/lead-campanha';

	return {
		...baseFindList<ICrmLeadCampanhaCleanDTO, ICrmLeadCampanhaDTO, ICrmLeadCampanhaFullDTO>(resourceURL),
	};
};
