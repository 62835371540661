import Rating from '@mui/material/Rating';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { format } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { styles } from "../../assets/sass/styles";
import { etapaIndex, etapaWord } from "../../components/Item/lista";
import { NotificationLead } from '../../components/Notification';
import TooltipStagio from '../../components/TooltipStagio';
import { useLeadContext } from '../../context/lead.context.service';
import { ICrmLeadDTO, ICrmLeadEstagioDTO } from '../../models/happy-code-api.model';
import { useLeadService } from "../../services/lead.service";
import { useVwLeadService } from '../../services/vwlead.service';
import { getStatusLead } from '../../util/lead.util';

interface props {
  children: JSX.Element,
  leadId: any;
  flExibirNotificacao?: boolean;
}

export const BodyLead = ({ children, leadId, flExibirNotificacao }: props) => {
  const leadService = useLeadService();
  const vwLeadService = useVwLeadService();
  const { setLeadSelecionado, leadSelecionado, state } = useLeadContext();
  const [lead, setLead] = useState<ICrmLeadDTO>();
  const [valueRating, setValueRating] = useState<number | undefined | null>(0);

  const findLead = () => {
    vwLeadService.findCustomIndexById({ id: leadId })
      .then((response) => {
        setValueRating(response?.data?.nrAvaliacaoLead);
        setLead(response?.data);
        setLeadSelecionado(response?.data);
      });
  }

  useEffect(() => {
    if (leadId) {
      findLead();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId])

  const onSalvarNrAvaliacao = (e) => {
    lead.nrAvaliacaoLead = e;
    setLead({ ...lead });
    leadService.patch(leadId, lead)
      .then(() => {
        findLead();
      });
  }

  const renderSteps = useMemo(() => {
    return state?.boards?.map((item: ICrmLeadEstagioDTO) => ({
      title: ' ',
      description: ' ',
      cdLeadEstagio: item.cdLeadEstagio,
    }));
  }, [state])

  const onEtapaClick = (cdLeadEstagio) => {
    leadService.alterarStatus(leadSelecionado?.id, cdLeadEstagio)
      .then(() => findLead());
  }

  return (
    <>
      <div className="header-panel flex flex-row align-start justify-start">
        <div className="box-panel">
          <h5>Funil de Etapa de Vendas</h5>
          <div>
            <Stepper activeStep={etapaIndex(leadSelecionado?.estagio?.cdLeadEstagio)} alternativeLabel style={{ color: '#304FFE' }}>
              {renderSteps.map((label, index) => (
                <Step key={label.title} style={{ color: '#304FFE' }}>
                  <TooltipStagio
                    title={etapaWord(label.cdLeadEstagio)}
                    stagioIndex={etapaIndex(leadSelecionado?.estagio?.cdLeadEstagio)}
                    index={index}>
                    <StepLabel
                      onClick={() => onEtapaClick(label.cdLeadEstagio)}
                      StepIconProps={{
                        sx: {
                          cursor: 'pointer',
                          "&.MuiSvgIcon-root": {
                            background: "#ECEFF1",
                            color: "#ECEFF1",
                            borderRadius: "24px",
                            fontSize: 20,
                          },
                          "&.Mui-completed": {
                            background: "#304FFE",
                            color: "#304FFE",
                            borderRadius: "24px",
                            fontSize: 20,
                          },
                          "&.Mui-active": {
                            background: "#304FFE",
                            color: "#304FFE",
                            borderRadius: "24px",
                            fontSize: 20,
                          },
                        }
                      }}
                      sx={
                        {
                          ".MuiStepIcon-text": { display: "none" },
                        }}
                    ></StepLabel>
                  </TooltipStagio>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
        <div className="box-panel">
          <h5>Qualificação</h5>
          <div className="mb-10">
            <Rating
              name="simple-controlled"
              value={valueRating ? valueRating : 0}
              onChange={(event, newValueRating) => {
                setValueRating(newValueRating);
                onSalvarNrAvaliacao(newValueRating);
              }}
              sx={styles.rating}
            />
          </div>
          {/* <h3>{leadSelecionado?.nrAvaliacaoLead} Estrelas</h3> */}
        </div>
        {leadSelecionado?.dataCriacao && <div className="box-panel">
          <h5>Data de Criação</h5>
          <h3>{format(new Date(leadSelecionado?.dataCriacao as any), 'dd/MM/yyyy')}</h3>
        </div>}
        <div className="box-panel">
          <h5>Quant. de Interações</h5>
          <h3>{leadSelecionado?.nrInteracao || 0}</h3>
        </div>
        {!!leadSelecionado?.dataAtualizacao && <div className="box-panel">
          <h5>Última Interação</h5>
          <h3>{format(new Date(leadSelecionado?.dataAtualizacao as any), 'dd/MM/yyyy')}</h3>
        </div>}
      </div>
      <div className="body-panel flex flex-row align-start justify-between w-100 gap-20">
        <div className="w-100">
          {leadSelecionado?.proximaTarefa && flExibirNotificacao && <NotificationLead status={getStatusLead(leadSelecionado?.proximaTarefa)} proximaTarefa={leadSelecionado?.proximaTarefa} />}
          {children}
        </div>
        {/* <div className="w-25">
          <div className="tarefa-panel">
            <MoreHorizIcon className="dot-absolute" />
            <div className="flex gap-10">
              <EmojiPeopleIcon />
              <h2>Dados pessoais</h2>
            </div>
            <div className="flex gap-10">
              <MailOutlineIcon />
              <h6>{leadSelecionado?.cdEmail}</h6>
            </div>
            <div className="flex gap-10">
              <WhatsAppIcon />
              <h6>{leadSelecionado?.cdDddCelular} {leadSelecionado?.cdCelular}</h6>
            </div>
            <div className="flex justify-between w-100">
              <h3>{'CPF / CNPJ'}</h3>
              <p>{leadSelecionado?.cdCpfCnpj || '--'}</p>
            </div>
            <div className="flex justify-between w-100">
              <h3>Origem</h3>
              <p>{leadSelecionado?.origem?.dsLeadOrigem || '--'}</p>
            </div>
            <div className="flex justify-between w-100">
              <h3 id="sem-margin">Campanha</h3>
              <p id="sem-margin">{leadSelecionado?.campanha?.dsLeadCampanha || '--'}</p>
            </div>
          </div>
          <div className="tarefa-panel">
            <MoreHorizIcon className="dot-absolute" />
            <div className="flex gap-10">
              <MapsHomeWorkIcon />
              <h2>Endereço</h2>
            </div>
            <div className="flex justify-between w-100">
              <h3>CEP</h3>
              <p>{leadSelecionado?.endereco?.cep}</p>
            </div>
            <div className="flex justify-between w-100">
              <h3>Cidade</h3>
              <p>{leadSelecionado?.endereco?.cidade}</p>
            </div>
            <div className="w-100 mb-20">
              <h3 id="sem-margin">Logradouro</h3>
              <p>{leadSelecionado?.endereco?.logradouro}</p>
            </div>
            <div className="flex justify-between w-100">
              <h3>Número</h3>
              <p>{leadSelecionado?.endereco?.numero}</p>
            </div>
            <div className="flex justify-between w-100">
              <h3 id="sem-margin">Bairro</h3>
              <p id="sem-margin">{leadSelecionado?.endereco?.bairro}</p>
            </div>
          </div>
          <div className="tarefa-panel">
            <MoreHorizIcon className="dot-absolute" />
            <div className="flex gap-10">
              <ShoppingCartIcon />
              <h2>Produto</h2>
            </div>
            <div className="w-100 mb-10">
              <h3 id="sem-margin">Produto de Interesse</h3>
              <p>{ultimoLead?.dsObservacao || '--'}</p>
            </div>
            <div className="line-cinza mb-10"></div>
            <div className="flex justify-between w-100">
              <h3 id="sem-margin">Valor:</h3>
              <p id="sem-margin">{ultimoLead?.vlLeadInteresse ? currencyFormatter(ultimoLead?.vlLeadInteresse) : '--'}</p>
            </div>
          </div>
          <div className="tarefa-panel">
            <MoreHorizIcon className="dot-absolute" />
            <div className="flex gap-10">
              <PeopleAltIcon />
              <h2>Responsável</h2>
            </div>
            <div className="flex gap-10 mb-20">
              <div className="circle-cinza flex flex-row justify-center align-center">
                <Unicons.UilUser style={{ cursor: 'pointer' }} size="20" color="var(--cinza-azulado-500)" />
              </div>
              <div>
                <h3 id="sem-margin">Usuário</h3>
                <p>{leadSelecionado?.usuario?.login}</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

