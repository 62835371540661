import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Button } from 'antd';
import ptBr from 'date-fns/locale/pt-BR';
import { useState } from 'react';
import { useGlobalContext } from '../../context/global.service';
import { useLeadContext } from '../../context/lead.context.service';
import { ICrmLeadTarefaCustomCleanDTO } from '../../models/happy-code-api.model';
import { useLeadTarefaService } from '../../services/lead-tarefa.service';
import { useNotificationService } from '../../services/notification.service';

interface Props {
  open: boolean;
  onEscolherData: () => void;
  onCancel?: () => void;
  tarefaSelecionada: ICrmLeadTarefaCustomCleanDTO;
}

export const ModalDatetimePicker = (props: Props) => {
  const { open, onEscolherData, onCancel, tarefaSelecionada } = props;
  const [dataHoraSelecionada, setDataHoraSelecionada] = useState<any>();
  const { setIsGlobalLoading } = useGlobalContext();
  const { refreshLeads } = useLeadContext();
  const tarefaService = useLeadTarefaService();
  const notification = useNotificationService();

  const selecionarData = () => {
    const dataSelecionada = new Date(dataHoraSelecionada);
    const novaDataSelecionada = new Date();
    novaDataSelecionada.setUTCDate(dataSelecionada.getDate());
    novaDataSelecionada.setUTCHours(dataSelecionada.getHours());
    novaDataSelecionada.setUTCMinutes(dataSelecionada.getMinutes());
    // const novaDataSelecionada = new Date(Date.UTC(dataSelecionada.getUTCFullYear(), dataSelecionada.getUTCMonth(),
    // dataSelecionada.getUTCDate(), dataSelecionada.getUTCHours(),
    // dataSelecionada.getUTCMinutes(), dataSelecionada.getUTCSeconds()));

    setIsGlobalLoading(true);
    tarefaService.patch({
      id: tarefaSelecionada.id,
      dhTarefa: novaDataSelecionada,
      hrTarefa: novaDataSelecionada,
    })
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          onEscolherData();
          refreshLeads();
          notification({ description: 'Data / horário da tarefa atualizado com sucesso', type: 'success' });
          return;
        }

        throw new Error();
      })
      .catch((e) => {
        notification({ description: 'Não foi possível atualizar a data e hora da tarefa', type: 'error' });
      })
      .finally(() => setIsGlobalLoading(false))
  }


  if (!open) return null

  return <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBr}>

    <div className="flex flex-row justify-center align-center modal-container" style={{ zIndex: 101 }}>
      <div className="flex flex-column justify-start align-start modal-resposta" style={{ zIndex: 101 }}>
        <h3>Selecione qual o estágio</h3>
        <div className="flex flex-column justify-end w-100">
          <DesktopDateTimePicker defaultValue={new Date(tarefaSelecionada?.dhTarefa)} onChange={setDataHoraSelecionada} />
          <Button onClick={selecionarData} className="button-primary mt-10 mr-5">Selecionar</Button>
          <Button onClick={onCancel} className="button-blue mt-10 mr-5">Cancelar</Button>
        </div>
      </div>
    </div>
  </LocalizationProvider>
}
