import {
	ICrmLeadHistoricoCleanDTO,
	ICrmLeadHistoricoDTO,
	ICrmLeadHistoricoFullDTO,
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useLeadHistoricoService = () => {
	const resourceURL = 'crm/lead-historico';

	return {
		...baseFindList<ICrmLeadHistoricoCleanDTO, ICrmLeadHistoricoDTO, ICrmLeadHistoricoFullDTO>(resourceURL),
		...baseFind<ICrmLeadHistoricoCleanDTO, ICrmLeadHistoricoDTO, ICrmLeadHistoricoFullDTO>(resourceURL),
	};
};
