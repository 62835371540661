import {
    ILeadHistoricoTipoCleanDTO,
    ILeadHistoricoTipoDTO,
    ILeadHistoricoTipoFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadHistoricoTipoService = () => {
	const resourceURL = 'crm/tipo-lead-historico';

	return {
		...baseFindList<ILeadHistoricoTipoCleanDTO, ILeadHistoricoTipoDTO, ILeadHistoricoTipoFullDTO>(resourceURL),
	};
};
