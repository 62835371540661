import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AuthEnum } from "../../enum/auth.enum";
import { useNotificationService } from "../../services/notification.service";
import { useUnidadeService } from "../../services/unidade.service";
import { setObject } from "../../util/store";

function Login() {
    const notification = useNotificationService();
    const unidadeService = useUnidadeService();
    const navigate = useNavigate()
    const windowUrl = window.location.search;

    const nameStorageLoginUnidade = '@HappyCode/login/unidade';

    useEffect(() => {
        if (!windowUrl) return;
        localStorage.clear();
        const params = new URLSearchParams(windowUrl);

        const tokenUrl = params.get('t');
        const unidadeUrl = params.get('u');

        if (!tokenUrl || tokenUrl?.length <= 0) {
            notification({ description: 'Token inválido', type: 'error' });
            return;
        }

        if (!unidadeUrl) {
            notification({ description: 'Unidade não encontrada', type: 'error' });
            return;
        }

        setObject(AuthEnum.TOKEN_KEY, { access_token: tokenUrl });

        const idUnidade = parseInt(unidadeUrl);
        unidadeService.findById({ id: idUnidade } as any).then((response) => {
            setObject(nameStorageLoginUnidade, response.data);
            setTimeout(() => {
                navigate("/");
            }, 2000);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window]);

    return (
        <div>

        </div>
    );
}

export default Login;
