import {
	ICrmLeadEstagioCleanDTO,
	ICrmLeadEstagioDTO,
	ICrmLeadEstagioFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadEstagioService = () => {
	const resourceURL = 'crm/lead-estagio';

	return {
		...baseFindList<ICrmLeadEstagioCleanDTO, ICrmLeadEstagioDTO, ICrmLeadEstagioFullDTO>(resourceURL),
	};
};
