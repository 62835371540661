import { useState } from 'react';
import { TimelineLead } from './timeline';
import { AnotacaoLead } from './anotacao';
import { TarefaLead } from './tarefa';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { styles } from '../../assets/sass/styles';
import { TabPanel, a11yProps } from './index';
import { useGlobalContext } from '../../context/global.service';
import { useLeadContext } from '../../context/lead.context.service';

export const HistoricoLead = () => {

  const [value, setValue] = useState(0);
  
  const { flModoEdicaoTarefa, setFlModoEdicaoTarefa, leadSelecionado, refreshLeadSelecionado } = useLeadContext();

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      {!flModoEdicaoTarefa && <div className="mb-20">
        <Box sx={styles.boxTabsComunication}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{ sx: { backgroundColor: 'transparent' } }}
            sx={styles.tabs}
          >
            <Tab key={1} icon={<NoteAddIcon sx={{ fontSize: 16 }} />} iconPosition="start" label="Criar anotação" {...a11yProps(0)} />
            <Tab key={2} icon={<CalendarTodayIcon sx={{ fontSize: 16 }} />} iconPosition="start" label="Criar tarefa" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </div>}
      <div id="tab-comunicacao">
        {!flModoEdicaoTarefa && <TabPanel value={value} index={0} >
          <AnotacaoLead />
        </TabPanel>}

        {flModoEdicaoTarefa && <TabPanel value={value} index={0} >
          <TarefaLead onClose={() => refreshLeadSelecionado()} tarefa={leadSelecionado?.proximaTarefa} edicao={true} />
        </TabPanel>}
        <TabPanel value={value} index={1}>
          <TarefaLead />
        </TabPanel>
      </div>
      <TimelineLead />
    </>
  )
}
