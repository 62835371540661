/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Unicons from '@iconscout/react-unicons';
import { Upload, Button, UploadProps } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { IArquivoCleanDTO } from '../../models/happy-code-api.model';
import { useNotificationService } from '../../services/notification.service';
import { token } from '../../util/store';
import SearchIcon from '@mui/icons-material/Search';
import { styles } from '../../assets/sass/styles';
interface Props {
    title: string;
    subtitle: string;
    action?: string;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setFileList: (arquivos: UploadFile<IArquivoCleanDTO>[]) => void;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onRemoveFile: (arquivo: any) => void;
    defaultFileList: any;
    statusDocumento: any;
    maxCount?: number;
    notRemove?: boolean;
    beforeUpload?: any;
}

export const BoxDocumentos: React.FunctionComponent<Props> = ({ title, subtitle, defaultFileList, statusDocumento, maxCount,
    setFileList, onRemoveFile, notRemove, beforeUpload, action }: Props) => {
    const notification = useNotificationService();

    const onChange = ({ file, fileList }: UploadChangeParam) => {
        const { status } = file;
        if (status === 'done') {
            notification({ description: `${file.name} file uploaded successfully.`, type: 'success', message: 'Sucesso!' });
            setFileList(fileList);
        } else if (status === 'error') {
            notification({ description: `${file.name} file upload failed.`, type: 'error', message: 'Falha!' });
        } else if (status === 'removed' && notRemove) {
            notification({ description: `${file.name} no authorization to remove file.`, type: 'error', message: 'Error!' });
        } else if (status === 'removed') {
            onRemoveFile(file);
        }
    }

    return (
        <Upload key={'upload-box-test'} beforeUpload={beforeUpload} defaultFileList={defaultFileList} maxCount={maxCount ?? null} headers={{ "Authorization": `Bearer ${token()}` }} action={action || `${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/upload`} onChange={onChange}>
            <Button className="button-bronce" icon={<SearchIcon style={{ color: 'var(--white)' }} />}>Selecionar arquivo</Button>
        </Upload>
    )
}