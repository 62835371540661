import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearIcon from '@mui/icons-material/Clear';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { Button, Input, Select } from "antd";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../context/global.service";
import { useLeadContext } from '../../context/lead.context.service';
import { ICrmLeadDTO, ICrmLeadMotivoPerdaCleanDTO } from '../../models/happy-code-api.model';
import { useLeadService } from "../../services/lead.service";
import { useNotificationService } from "../../services/notification.service";
import { useVwLeadService } from '../../services/vwlead.service';
import { getStatusLead } from '../../util/lead.util';
import { statusWord } from "../Lead";
import { Predicate } from '../../models/predicate.model';
import { useLeadMotivoPerdaService } from '../../services/lead-motivo-perda';

interface Props {
  leadId: string | undefined;
}

export const HeaderLead = ({ leadId }: Props) => {

  const navigate = useNavigate();

  const leadService = useLeadService();
  const vwLeadService = useVwLeadService();
  const notification = useNotificationService();

  const { setLeadSelecionado, leadSelecionado } = useLeadContext();
  const { setIsGlobalLoading } = useGlobalContext();
  const leadMotivoPerdaService = useLeadMotivoPerdaService();

  const [openModalMarcarPerda, setOpenModalMarcarPerda] = useState<boolean>();
  const [dsMotivoPerda, setDsMotivoPerda] = useState<string>();
  const [dsObservacoesPerda, setDsObservacoesPerda] = useState<string>();
  const [leadMotivoPerdas, setLeadMotivoPerdas] = useState<ICrmLeadMotivoPerdaCleanDTO[]>();

  const findMotivoPerda = () => {
    const predicate = new Predicate();
    predicate.addOption('ativo', true);

    leadMotivoPerdaService.findCleanList(predicate).then((response) => {
      setLeadMotivoPerdas(response?.data);
    });
  }

  const acessoExternoSpace = () => {
    window.open(`${process.env.REACT_APP_HAPPYCODE_PUBLIC_URL_SPACE}alunos/create/step-1/lead/${leadId}`, "_blank");
  };

  useEffect(() => {
    findMotivoPerda();
    if (openModalMarcarPerda !== null && openModalMarcarPerda !== undefined && openModalMarcarPerda === false) {
      setDsObservacoesPerda(null);
      setDsMotivoPerda(null);
    }
  }, [openModalMarcarPerda]);

  const onAlterarStatusLead = (cdEstagio: string, dsMensagemSucesso: string) => {

    const isPerda = cdEstagio === 'DES';

    if (isPerda && (!dsMotivoPerda || dsMotivoPerda?.length <= 0 || dsMotivoPerda === 'selecione')) {
      notification({ description: 'Selecione o motivo da perda', type: 'error' });
      return;
    }

    setIsGlobalLoading(true);
    
    leadService.alterarStatus(leadId as any, cdEstagio).then((response) => {
      if (response.status >= 200 && response.status <= 299) {

        const body: ICrmLeadDTO = {
          id: leadId as any,
          dsMotivoPerda: dsMotivoPerda,
          dsObservacaoPerda: dsObservacoesPerda,
          campanha: leadSelecionado?.campanha,
        } as ICrmLeadDTO;
        
        return leadService.patch(leadId as any, body).then((response) => {
          if (response.status >= 200 && response.status <= 299) {
            setOpenModalMarcarPerda(false);
            return vwLeadService.findCustomIndexById({ id: leadId as any })
              .then((response) => {
                setLeadSelecionado({ ...response?.data });
                notification({ description: dsMensagemSucesso, type: 'success' })
                if (!isPerda) {
                  acessoExternoSpace();
                }
              });
          }

          throw new Error();

        });
      }
      throw new Error();
    }).catch((e) => {
      notification({ description: 'Não foi possível transferir o Lead.', type: 'error' })
    }).finally(() => setIsGlobalLoading(false));
  }

  const status = getStatusLead(leadSelecionado?.proximaTarefa);
  const statusFormatada = statusWord(status);

  return (
    <>
      <div className="header-lead flex justify-center" style={{ height: 150 }}>
        <div className="flex flex-row justify-start align-center w-50 gap-20">
          <ArrowBackIcon onClick={() => navigate("/")} className="icone-back" />
          <div className={`box-tag-${status}`}>
            <p>{statusFormatada}</p>
          </div>
          <h5>{leadSelecionado?.dsNomeLead}</h5>
        </div>
        <div className="flex flex-row w-50 align-center justify-end">
          <Button onClick={() => onAlterarStatusLead('MTO', 'Lead transferido para etapa matriculado')} className="button-verde gap-5" icon={<ThumbUpIcon style={{ fontSize: 16 }} />}>
            Marcar Venda
          </Button>
          <Button onClick={() => setOpenModalMarcarPerda(true)} className="button-red ml-10 gap-5" icon={<ThumbDownIcon style={{ fontSize: 16 }} />}>
            Marcar Perda
          </Button>
          {/* <Button className="button-primary ml-10">Salvar Lead</Button> */}
        </div>
      </div>
      <Modal
        open={openModalMarcarPerda}
        onClose={() => setOpenModalMarcarPerda(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 1000,
          },
        }}
      >
        <Fade in={openModalMarcarPerda}>
          <div className="modal-note" style={{ width: 650, maxWidth: 650 }}>
            <div className="container-modal">
              <ClearIcon className="button-close" onClick={() => setOpenModalMarcarPerda(false)} />
              <h3>Registrar perda do Lead</h3>

              <div className="select-box mr-5 flex row" style={{ alignItems: 'center', width: '87%' }}>
                <div className="text-over-input" style={{ marginBottom: 15, paddingRight: 10, flex: 1, minWidth: '25%' }}>
                  <label>Motivo da perda</label>
                </div>
                <div className="input-modal mb-10" style={{ minWidth: '89%' }}>
                  <Select
                    dropdownStyle={{ zIndex: 2000 }}
                    style={{ width: '100%' }}
                    bordered={false}
                    placeholder="Selecione o currículo"
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    defaultValue="selecione"
                    value={dsMotivoPerda}
                    onChange={(e) => setDsMotivoPerda(e)}
                    filterOption={(input, option) =>
                      option && option?.text?.toLowerCase().includes(input.toLowerCase())
                    }>
                    {leadMotivoPerdas && leadMotivoPerdas.map((item) =>
                      <Select.Option key={item?.id} value={item?.dsLeadMotivoPerda} text={item?.dsLeadMotivoPerda}>{item?.dsLeadMotivoPerda}</Select.Option>
                    )}
                  </Select>
                </div>
              </div>

              <div className="select-box mr-5" style={{ alignItems: 'center', width: '100%' }}>
                <div className="text-over-input" style={{ marginBottom: 15, paddingRight: 10 }}>
                  <label>Observações (opcional)</label>
                </div>
                <Input.TextArea
                  value={dsObservacoesPerda}
                  name="" id=""
                  rows={5}
                  onChange={(e) => setDsObservacoesPerda(e.target.value)}
                  placeholder='Digite as observações aqui...'
                />

                <p>{dsObservacoesPerda?.length || 0}/500</p>
              </div>

              <div className="flex row" style={{ justifyContent: 'flex-end' }}>
                <Button className="button-blue" onClick={() => setOpenModalMarcarPerda(false)} >Cancelar</Button>
                <Button className="button-primary" onClick={() => onAlterarStatusLead('DES', 'Lead transferido para etapa descartado')}>Registrar</Button>
              </div>

              {/* <Input.TextArea
                value={dsTextoAnotacao}
                name=""
                id=""
                rows={10}
                placeholder="Descreva sua anotação"
                onChange={(e) => setDsTextoAnotacao(e.target.value as any)} />
              <p>{dsTextoAnotacao?.length || 0}/500</p>
              <div className="flex flex-row justify-between aling-center gap-10">
                <Button className="button-primary" onClick={handleOpenModalTarefa}>Ver Tarefas</Button>
                <Button className="button-blue" onClick={handleCloseModalNewNote}>Salvar</Button>
              </div> */}
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  )
}
