import {
	ILeadContatoCleanDTO,
	ILeadContatoDTO,
	ILeadContatoFullDTO
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindList } from './base/base-find-list.service';
import { baseUpdate } from './base/base-update.service';

export const useLeadContatoCampanhaService = () => {
	const resourceURL = 'crm/lead-contato';

	return {
		...baseFindList<ILeadContatoCleanDTO, ILeadContatoDTO, ILeadContatoFullDTO>(resourceURL),
		...baseCreate<ILeadContatoCleanDTO, ILeadContatoDTO, ILeadContatoFullDTO>(resourceURL),
		...baseUpdate<ILeadContatoCleanDTO, ILeadContatoDTO, ILeadContatoFullDTO>(resourceURL),
	};
};
