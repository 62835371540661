import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Button, Input } from "antd";
import { useEffect, useMemo, useState } from 'react';
import InputMask from 'react-input-mask';
import { ModalResposta } from '../../components/Modal/resposta';
import { useGlobalContext } from '../../context/global.service';
import { useLeadContext } from '../../context/lead.context.service';
import { ILeadContatoCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useLeadContatoCampanhaService } from '../../services/lead-contato.service';
import { useNotificationService } from '../../services/notification.service';


export const ContatosLead = () => {

  const [openModalAddUser, setOpenModalAddUser] = useState(false);

  const handleOpenModalAddUser = () => {
    if (validarForm()) {
      setOpenModalAddUser(true);
    }
  }


  const leadContatoService = useLeadContatoCampanhaService();
  const { setIsGlobalLoading } = useGlobalContext();
  const { leadSelecionado } = useLeadContext();
  const notification = useNotificationService();

  const [contatos, setContatos] = useState<ILeadContatoCleanDTO[]>();
  const [dsNomeContato, setDsNomeContato] = useState<string>();
  const [dsTelefone, setDsTelefone] = useState<string>();
  const [dsEmail, setDsEmail] = useState<string>();

  //const contatosFiltrados = contatos?.filter((item) => item[dsNomeContato].toLowerCase().includes(valor.toLowerCase()));

  const fetchContatos = () => {
    const predicate = new Predicate();
    predicate.addOption('ativo', true);
    predicate.addOption('lead.id', leadSelecionado?.id);

    return leadContatoService.findCleanList(predicate).then(response => {
      if (response.status === 200) {
        setContatos(response.data);
        //setContatosFiltrados(response.data);
      }
      return response;
    })
  }

  useEffect(() => {

    if (leadSelecionado) {
      setIsGlobalLoading(true);
      fetchContatos().finally(() => setIsGlobalLoading(false));

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadSelecionado]);

  const validarForm = () => {
    if (dsNomeContato?.trim?.()?.length <= 0 ||
      removerMascara(dsTelefone || '')?.trim?.()?.length <= 0 ||
      dsEmail?.trim?.()?.length <= 0) {
      notification({ description: 'Preencha todos os campos', type: 'warning', message: 'Atenção' })
      setOpenModalAddUser(false);
      return false;
    }

    if (!emailValido) {
      notification({ description: 'Digite um e-mail válido', type: 'warning', message: 'Atenção' });
      setOpenModalAddUser(false);
      return false;
    }

    return true;

  }

  const adicionarContato = () => {

    const body = {
      dsNome: dsNomeContato,
      dsTelefone: removerMascara(dsTelefone),
      dsEmail,
      lead: {
        id: leadSelecionado.id
      }
    }
    setIsGlobalLoading(true);

    leadContatoService.create(body).then((response) => {
      if (response.status >= 200 && response.status <= 299) {
        fetchContatos();
        notification({ description: 'Contato cadastrado!', type: 'success', message: 'Sucesso!' });
        setDsNomeContato(null);
        setDsTelefone(null);
        setDsEmail(null);
        setOpenModalAddUser(false);
      }
    }).finally(() => setIsGlobalLoading(false))
  }

  const limparModalFechar = () => {
    setDsNomeContato(null);
    setDsTelefone(null);
    setDsEmail(null);
    setOpenModalAddUser(false);
  }

  // const onFiltrar = (dsCampo: string, valor: string) => {
  //   try {
  //     const valores = contatos?.filter((item) => item[dsCampo].toLowerCase().includes(valor.toLowerCase()));
  //     setContatosFiltrados(valores);
  //   } catch (e) {
  //     return;
  //   }
  // }

  function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const emailValido = useMemo(() => {
    if (dsEmail === null || dsEmail === undefined) return false;

    return validateEmail(dsEmail);
  }, [dsEmail])

  const removerMascara = (e = '') => {
    return e.replaceAll('_', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '');
  }

  const onRemoverContato = (item: ILeadContatoCleanDTO) => {
		const body = {
			id: item.id,
			ativo: false,
		};

		setIsGlobalLoading(true);

		leadContatoService
			.patch(body)
			.then((response) => {
				if (response.status >= 200 && response.status <= 299) {
					fetchContatos();
					notification({ description: 'Contato removido!', type: 'success' });
					return;
				}

				throw new Error();
			})
			.catch(() => {
				notification({ description: 'Ocorreu um erro ao remover o contato', type: 'error' });
			})
			.finally(() => {
				setIsGlobalLoading(false);
			});
	};

  return (
		<>
			<div className="tarefa-panel">
				<div className="flex justify-between gap-20 mb-20">
					<div className="flex align-center gap-10">
						<ContactMailOutlinedIcon />
						<h1 id="sem-margin">Contatos vinculados ao lead</h1>
					</div>
					{/* onFiltrar */}
				</div>
				<div className="box-documentos mb-20 shadow-lead" style={{ background: 'white' }}>
					<form action="" className="flex flex-wrap gap-20">
						<div className="flex align-center gap-10 w-100">
							<label htmlFor="">Nome do contato</label>
							<div className="box-white border-input-lead">
								<Input
									value={dsNomeContato}
									onChange={(e) => setDsNomeContato(e.target.value)}
									bordered
									placeholder="Digite o nome do contato"
									className="input-box"
								/>
							</div>
						</div>
						<div className="flex align-center gap-10 w-50">
							<label htmlFor="">Telefone</label>
							<div className="box-white border-input-lead">
								{/* <Input
                  onChange={(e) => setDsTelefone(e.target.value)}
                  placeholder="(00) 00000-0000" className="input-box" /> */}

								<InputMask
									mask="(99) (99999-9999)"
									maskPlaceholder="(99) (99999-9999)"
									value={dsTelefone ?? ''}
									onChange={(e) => {
										setDsTelefone(e.target.value);
									}}
								>
									{(inputProps) => <Input {...inputProps} placeholder="Telefone" className="input-box" />}
								</InputMask>
							</div>
						</div>
						<div className="flex align-center gap-10 flex-fill">
							<label htmlFor="">E-mail</label>
							<div className="box-white border-input-lead">
								<Input value={dsEmail} onChange={(e) => setDsEmail(e.target.value)} placeholder="usuário@email.com.br" className="input-box" />
							</div>
						</div>
					</form>

					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 25 }}>
						<Button onClick={handleOpenModalAddUser} className="button-bronce" icon={<PersonAddIcon style={{ color: 'var(--white)' }} />}>
							Adicionar Contato
						</Button>
					</div>
				</div>
				<table className="w-100 mb-20">
					<tbody>
						{contatos?.map((contato) => {
							return (
								<tr key={contato.id}>
									<td className="flex align-center">
										<div className="circle-cinza">
											<PersonIcon sx={{ color: 'var(--cinza-azulado-200)' }} />
										</div>
										<p>{contato.dsNome}</p>
									</td>
									<td>
										<p>{contato.dsEmail}</p>
									</td>
									<td>
										<p>{contato.dsTelefone}</p>
									</td>
									<td className="flex align-center justify-end gap-10">
										<a target="_blank" href={`mailto:${removerMascara(contato.dsEmail)}`} rel="noreferrer">
											<MailOutlinedIcon sx={{ fontSize: 24, color: 'var(--cinza-azulado-400)' }} />
										</a>
										<a target="_blank" href={`https://wa.me/${removerMascara(contato.dsTelefone)}`} rel="noreferrer">
											<WhatsAppIcon sx={{ fontSize: 24, color: 'var(--cinza-azulado-400)' }} />
										</a>
										<DeleteOutlineOutlinedIcon
											sx={{ fontSize: 24, color: 'var(--cinza-azulado-400)' }}
											style={{ cursor: 'pointer' }}
											onClick={() => onRemoverContato(contato)}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			{/* <Modal
        open={openModalAddUser}
        onClose={limparModalFechar}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 1000,
          },
        }}
      >
        <Fade in={openModalAddUser}>
          <div className="modal-note" style={{ width: 600, maxWidth: 600 }}>
            <div className="container-modal">
              <ClearIcon className="button-close" onClick={limparModalFechar} />
              <h3>Adicionar contato</h3>
              <Input onChange={(e) => setDsNomeContato(e.target.value)}
                placeholder="Nome do contato" className="mb-20 h-50" />
              <Input onChange={(e) => setDsEmail(e.target.value)}
                placeholder="E-mail" className="h-50" />
              {!emailValido && dsEmail?.length >= 0 && <small>Digite um e-mail válido</small>}
              <div className='mb-20 ' />

              <InputMask
                mask='(99) (99999-9999)'
                maskPlaceholder="(99) (99999-9999)"
                value={dsTelefone ?? ""}
                onChange={(e) => {
                  setDsTelefone(e.target.value)
                }}
              >
                {(inputProps) => <Input {...inputProps} placeholder="Telefone" className="mb-20 h-50" />}
              </InputMask>
              <div className="flex flex-row justify-end aling-center gap-10">
                <Button className="button-primary" onClick={limparModalFechar}>Cancelar</Button>
                <Button className="button-blue" onClick={adicionarContato}>Salvar</Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal> */}

			<ModalResposta
				open={openModalAddUser}
				onClose={adicionarContato}
				onCancel={limparModalFechar}
				status="Deseja cadastrar contato?"
				text="Confirme os dados antes de cadastrar"
				link=""
			/>
		</>
	);
}
