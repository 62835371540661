import { Form } from "antd";
import { useEffect, useState } from 'react';
import { ModalResposta } from '../../components/Modal/resposta';
import { useGlobalContext } from '../../context/global.service';
import { ICrmLeadTarefaCustomCleanDTO } from '../../models/happy-code-api.model';
import { useNotificationService } from '../../services/notification.service';

import CalendarMonth from '@mui/icons-material/CalendarMonth';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import RestoreIcon from '@mui/icons-material/Restore';
import { format } from 'date-fns';
import { statusWord, styleNote } from '../../components/Lead';
import { ModalAdiarTarefa } from '../../components/Modal/adiar-tarefa';
import { ModalDatetimePicker } from '../../components/Modal/datetime-picker';
import { ModalEditarTarefa } from '../../components/Modal/editar-tarefa';
import { Predicate } from '../../models/predicate.model';
import { useLeadTarefaService } from '../../services/lead-tarefa.service';
import { getStatusLead } from '../../util/lead.util';
import { useLeadContext } from "../../context/lead.context.service";
import { AxiosResponse } from "axios";
import { PageableResponse } from "../../models/response.model";
import { Pagination } from "@mui/material";


export const TarefasLead = () => {
	//const [tarefas, setTarefas] = useState<ICrmLeadTarefaCustomCleanDTO[]>([]);
	const [page, setPage] = useState<any>();
	const [openModalRemoverTarefa, setOpenModalRemoverTarefa] = useState(false);
	const [tarefaSelecionada, setTarefaSelecionada] = useState<ICrmLeadTarefaCustomCleanDTO>();
	const [tarefasResponse, setTarefasResponse] = useState<AxiosResponse<PageableResponse<ICrmLeadTarefaCustomCleanDTO>>>();

	const [flDateTimePicker, setFlDateTimePicker] = useState<boolean>();
	const [flModalMarcarConcluido, setFlModalMarcarConcluido] = useState<boolean>();
	const [flModalAdiarTarefa, setFlModalAdiarTarefa] = useState<boolean>();
	const [flModalEditarTarefa, setFlModalEditarTarefa] = useState<boolean>();

	const [form] = Form.useForm();
	const { setIsGlobalLoading } = useGlobalContext();
	const { leadSelecionado } = useLeadContext();

	const notification = useNotificationService();
	const tarefaService = useLeadTarefaService();

	useEffect(() => {
		if (leadSelecionado) {
			buscarTarefas();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leadSelecionado]);

	useEffect(() => {
		if (page !== null && page !== undefined) {
			buscarTarefas();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const buscarTarefas = () => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		predicate.addOption('lead.id', leadSelecionado.id);
		predicate.addOption('flConcluido', false);

		page !== null && page !== undefined && predicate.setPage(page);

		setIsGlobalLoading(true);
		return tarefaService
			.findCustom(predicate)
			.then((response) => {
				//setTarefas(response.data);
				setTarefasResponse(response);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const onPageChange = (event, page) => {
		event.preventDefault();
		setPage(page);
	};

	const onRemoverTarefaClick = () => {
		setIsGlobalLoading(true);
		const tarefaTemp: any = {
			...tarefaSelecionada,
			ativo: false,
		};
		return tarefaService
			.patch(tarefaTemp)
			.then((response) => {
				if (response.status >= 200 && response.status <= 299) {
					setOpenModalRemoverTarefa(false);
					setTarefaSelecionada(null);
					return buscarTarefas();
				}

				throw new Error();
			})
			.catch((e) => {
				notification({ description: 'Não foi possível remover a tarefa.', type: 'error' });
			})
			.finally(() => {
				setIsGlobalLoading(false);
			});
	};

	const onDeleteClick = (tarefa: ICrmLeadTarefaCustomCleanDTO) => {
		setOpenModalRemoverTarefa(true);
		setTarefaSelecionada(tarefa);
	};

	const onConcluirTarefa = () => {
		setIsGlobalLoading(true);
		tarefaService
			.patch({
				id: tarefaSelecionada.id,
				flConcluido: true,
			})
			.then((response) => {
				if (response.status >= 200 && response.status <= 299) {
					buscarTarefas();
					notification({ description: 'Tarefa marcada como concluída.', type: 'success' });
					return;
				}

				throw new Error();
			})
			.catch((e) => {
				notification({ description: 'Não foi possível marcar como concluído.', type: 'error' });
			})
			.finally(() => setIsGlobalLoading(false));

		setFlModalMarcarConcluido(false);
	};

	return (
		<>
			<Form
				style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}
				name="basic"
				// initialValues={{ remember: true }}
				form={form}
			>
				<div className="tarefa-panel" id="dados-lead">
					<div className="flex justify-between flex-column gap-20 mb-20">
						<div className="flex align-center gap-10">
							<CalendarMonth />
							<h1 id="sem-margin">Tarefas</h1>
						</div>
					</div>

					<table className="w-100 mb-20">
						<thead>
							<tr>
								<th className="w-20 header-row pl-20">Assunto</th>
								<th className="w-10 header-row">Tipo</th>
								<th className="w-20 header-row">Status</th>
								<th className="w-20 header-row">Data / Hora</th>
								<th className="w-30 header-row" colSpan={2}>
									Responsável
								</th>
							</tr>
						</thead>

						<tbody>
							{tarefasResponse?.data?.content?.map((tarefa: ICrmLeadTarefaCustomCleanDTO) => {
								const status = getStatusLead(tarefa);
								return (
									<tr key={tarefa?.id}>
										<td className="flex align-center pl-20">
											<p>{tarefa?.dsAssunto}</p>
										</td>
										<td>
											<div className="flex">
												<p>{tarefa?.dsTipoTarefa || '--'}</p>
											</div>
										</td>
										<td>
											<div className="flex">
												<div className={`tag-${status || 'novo'} flex flex-row justify-between align-center`}>
													<CheckBoxIcon style={{ color: styleNote(status) }} />
													<span style={{ marginLeft: 10 }}>{statusWord(status)}</span>
												</div>
											</div>
										</td>
										<td>
											<p>{tarefa?.dhTarefa ? format(new Date(tarefa?.dhTarefa), 'dd/MM/yyyy') + ' ' + format(new Date(tarefa?.hrTarefa), 'HH:mm') : '--'}</p>
										</td>
										<td>
											<div className="flex">
												<p>{tarefa?.nmResponsavel || '--'}</p>
											</div>
										</td>
										<td className="flex align-center justify-end gap-10" style={{ cursor: 'pointer' }}>
											<DeleteOutlineOutlinedIcon sx={{ fontSize: 24, color: 'var(--cinza-azulado-400)' }} onClick={() => onDeleteClick(tarefa)} />
											<EditIcon
												style={{ cursor: 'pointer' }}
												sx={{ color: 'var(--cinza-azulado-400)' }}
												onClick={() => {
													setTarefaSelecionada(tarefa);
													setTimeout(() => setFlModalEditarTarefa(true), 1000);
												}}
											/>
											<RestoreIcon
												style={{ cursor: 'pointer' }}
												sx={{ color: 'var(--cinza-azulado-400)' }}
												onClick={() => {
													setTarefaSelecionada(tarefa);
													setTimeout(() => setFlModalAdiarTarefa(true), 1000);
												}}
											/>
											<CheckCircleIcon
												style={{ cursor: 'pointer' }}
												sx={{ color: 'var(--cinza-azulado-400)' }}
												onClick={() => {
													setTarefaSelecionada(tarefa);
													setTimeout(() => setFlModalMarcarConcluido(true), 1000);
												}}
											/>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>

					<div className="table-footer flex justify-end">
						<Pagination
							onChange={(event, page) => onPageChange(event, page - 1)}
							page={tarefasResponse?.data ? tarefasResponse?.data.pageable.pageNumber + 1 : 0}
							count={tarefasResponse?.data ? tarefasResponse?.data.totalPages : 0}
						/>
					</div>
				</div>

				<ModalResposta
					open={openModalRemoverTarefa}
					onClose={onRemoverTarefaClick}
					onCancel={() => {
						setOpenModalRemoverTarefa(false);
						setTarefaSelecionada(null);
					}}
					status="Remover tarefa"
					text="Deseja mesmo remover esta tarefa?"
					link=""
				/>

				<ModalAdiarTarefa
					open={flModalAdiarTarefa}
					onClose={() => setFlModalAdiarTarefa(false)}
					onAbrirDatetimePicker={() => setFlDateTimePicker(true)}
					tarefaSelecionada={tarefaSelecionada}
				/>

				<ModalEditarTarefa
					open={flModalEditarTarefa}
					onClose={() => {
						setFlModalEditarTarefa(false);
						buscarTarefas();
					}}
					onCancel={() => {
						setFlModalEditarTarefa(false);
						setTarefaSelecionada(null);
					}}
					tarefa={tarefaSelecionada}
				/>
				<ModalDatetimePicker
					open={flDateTimePicker}
					onCancel={() => setFlDateTimePicker(false)}
					onEscolherData={() => {
						setFlModalAdiarTarefa(false);
						setFlDateTimePicker(false);
						buscarTarefas();
					}}
					tarefaSelecionada={tarefaSelecionada}
				/>
				<ModalResposta
					text="Deseja concluir a tarefa?"
					open={flModalMarcarConcluido}
					onClose={onConcluirTarefa}
					onCancel={() => {
						setFlModalMarcarConcluido(false);
					}}
					status="A tarefa será concluída"
				/>
			</Form>
		</>
	);
}
