import * as Unicons from '@iconscout/react-unicons';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ClearIcon from '@mui/icons-material/Clear';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import StarIcon from '@mui/icons-material/Star';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import Backdrop from '@mui/material/Backdrop';
import Checkbox from '@mui/material/Checkbox';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { Button, Input } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../context/global.service";
import { useLeadService } from "../../services/lead.service";
import { statusWord, styleNote } from "../Lead";

interface Props {
  id: number,
  etapa: string,
  cliente: string,
  status: string,
  dataInicio: string,
  note: boolean,
  qualify: number,
  supervisor: string,
  dsNote: string,
  iteracoes: number,
  ultimaIteracao: string,
  cdStatus?: string,
  checked?: boolean,
  onAction: (e: any) => void
}

export const etapaWord = (etapa: string | undefined) => {
  switch (etapa) {
    case 'PRC':
      return 'Primeiro Contato';
    case 'APR':
      return 'Apresentação';
    case 'PRA':
      return 'Proposta';
    case 'MTO':
      return 'Matriculado';
    case 'DES':
      return 'Descartado';
    default:
      return 'Matriculado';
  }
}

export const etapaIndex = (etapa: string | undefined) => {
  switch (etapa) {
    case 'PRC':
      return 0;
    case 'APR':
      return 1;
    case 'PRA':
      return 2;
    case 'MTO':
      return 3;
    case 'DES':
      return 4;
    default:
      return 3;
  }
}

export const Itemlista = ({
  id,
  cliente,
  status,
  cdStatus,
  dataInicio,
  note,
  qualify = 0,
  supervisor,
  iteracoes,
  ultimaIteracao,
  onAction,
  dsNote = '',
  checked,
}: Props) => {

  const [isStyle, setIsStyle] = useState('row-item');
  const [openModalNewNote, setOpenModalNewNote] = useState(false);
  const [openModalNote, setOpenModalNote] = useState(false);
  const [openModalTarefa, setOpenModalTarefa] = useState(false);

  const leadService = useLeadService();

  const handleOpenModalNewNote = () => {
    setOpenModalNewNote(true);
    setOpenModalTarefa(false);
    setOpenModalNote(false);
  }
  const handleCloseModalNewNote = () => setOpenModalNewNote(false);

  const handleOpenModalNote = () => {
    setOpenModalNote(true);
    setOpenModalNewNote(false);
    setOpenModalTarefa(false);
  }
  const handleCloseModalNote = () => setOpenModalNote(false);

  const handleOpenModalTarefa = () => {
    setOpenModalTarefa(true);
    setOpenModalNewNote(false);
    setOpenModalNote(false);
  }
  const handleCloseModalTarefa = () => setOpenModalTarefa(false);

  const onChangeStyle = () => {
    if (checked) {
      setIsStyle('row-item-select')
    } else {
      setIsStyle('row-item')
    }
  }



  return (
    <>

      <li key={id} className={`${checked ? 'row-item-select' : 'row-item'} flex flex-row justify-strat align-center`} >
        <div style={{ width: 100 }} className="item flex flex-row justify-center align-center gap-10">
          <Checkbox
            sx={{
              color: 'var(--cinza-azulado) !important',
              '&.Mui-checked': {
                color: 'var(--azul-happy) !important',
              },
            }}
            // onChange={onChangeStyle}
            onClick={onAction}
            value={id}
            checked={checked}
          />

          {note === false ?
            <NoteAddIcon
              style={{ color: 'var(--cinza-azulado)', cursor: 'pointer' }}
              onClick={handleOpenModalNewNote}
            />
            :
            <div className="note-box">
              <StickyNote2Icon
                style={{ color: styleNote(status), cursor: 'pointer' }}
                onClick={handleOpenModalNote}
              />
              <div className="dot-red"></div>
            </div>
          }
        </div>
        <Link to={`/lead/${id}`} style={{ textDecoration: 'none', display: 'flex', flex: 1, width: '100%' }} className="flex align-center">
          <div style={{ width: 250 }} className="item flex align-center">
            <p>{cliente}</p>
          </div>
          <div style={{ width: 250 }} className="item flex align-center">
            <div className={`tag-${status} flex flex-row justify-between align-center`}>
              <CheckBoxIcon style={{ color: styleNote(status) }} />
              <span style={{ marginLeft: 10 }}>{statusWord(status)}</span>
            </div>
          </div>
          <div style={{ width: 250 }} className="item flex align-center">
            <p>{etapaWord(cdStatus)}</p>
          </div>
          <div style={{ width: 250 }} className="item flex align-center">
            <div className="starts">
              <Checkbox
                icon={<StarBorderOutlinedIcon />}
                style={{cursor: 'pointer'}}
                checkedIcon={<StarIcon />}
                sx={{
                  color: 'var(--cinza-azulado) !important',
                  '&.Mui-checked': {
                    color: 'var(--azul-happy) !important',
                  },
                }}
                checked={qualify >= 1}
              />
              <Checkbox
                icon={<StarBorderOutlinedIcon />}
                style={{cursor: 'pointer'}}
                checkedIcon={<StarIcon />}
                sx={{
                  color: 'var(--cinza-azulado) !important',
                  '&.Mui-checked': {
                    color: 'var(--azul-happy) !important',
                  },
                }}
                checked={qualify >= 2}
              />
              <Checkbox
                icon={<StarBorderOutlinedIcon />}
                style={{cursor: 'pointer'}}
                checkedIcon={<StarIcon />}
                sx={{
                  color: 'var(--cinza-azulado) !important',
                  '&.Mui-checked': {
                    color: 'var(--azul-happy) !important',
                  },
                }}
                checked={qualify >= 3}
              />
              <Checkbox
                icon={<StarBorderOutlinedIcon />}
                style={{cursor: 'pointer'}}
                checkedIcon={<StarIcon />}
                sx={{
                  color: 'var(--cinza-azulado) !important',
                  '&.Mui-checked': {
                    color: 'var(--azul-happy) !important',
                  },
                }}
                checked={qualify >= 4}
              />
              <Checkbox
                icon={<StarBorderOutlinedIcon />}
                style={{cursor: 'pointer'}}
                checkedIcon={<StarIcon />}
                sx={{
                  color: 'var(--cinza-azulado) !important',
                  '&.Mui-checked': {
                    color: 'var(--azul-happy) !important',
                  },
                }}
                checked={qualify >= 5}
              />
            </div>
          </div>
          <div style={{ width: 250 }} className="item flex align-center">
            <p style={{ color: '#78909C' }}>{dataInicio}</p>
          </div>
          <div style={{ width: 250 }} className="item flex align-center">
            <div className="circle-cinza flex flex-row justify-center align-center">
              <Unicons.UilUser style={{ cursor: 'pointer' }} size="20" color="var(--cinza-azulado-500)" />
            </div>
            <p style={{ marginLeft: 15, color: '#78909C' }}>{supervisor}</p>
          </div>
          <div style={{ width: 250, paddingLeft: 75 }} className="item flex align-center">
            <p style={{ color: '#78909C' }}>{iteracoes}</p>
          </div>
          <div style={{ width: 250 }} className="item flex align-center">
            <p style={{ color: '#78909C' }}>{ultimaIteracao}</p>
          </div>
          {/* <div style={{ width: 250, paddingLeft: 35 }} className="item flex align-center">
            <RemoveRedEyeIcon style={{ color: 'var(--azul-happy)' }} />
          </div> */}
        </Link>
      </li>

      <Modal
        open={openModalNewNote}
        onClose={handleCloseModalNewNote}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 1000,
          },
        }}
      >
        <Fade in={openModalNewNote}>
          <div className="modal-note">
            <div className="container-modal">
              <ClearIcon className="button-close" onClick={handleCloseModalNewNote} />
              <h3>Criar Anotação</h3>
              <Input.TextArea name="" id="" rows={10}>
                Descreva sua anotação
              </Input.TextArea>
              <p>0/500</p>
              <div className="flex flex-row justify-between aling-center gap-10">
                <Button className="button-primary" onClick={handleOpenModalTarefa}>Ver Tarefas</Button>
                <Button className="button-blue" onClick={handleCloseModalNewNote}>Salvar</Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        open={openModalNote}
        onClose={handleCloseModalNote}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 1000,
          },
        }}
      >
        <Fade in={openModalNote}>
          <div className="modal-note">
            <div className="container-modal">
              <ClearIcon className="button-close" onClick={handleCloseModalNote} />
              <h3>Anotação</h3>
              <h6>{dsNote}</h6>
              <div className="flex flex-row justify-between aling-center gap-10">
                {/* <Button className="button-primary" onClick={handleOpenModalTarefa}>Ver Tarefas</Button> */}
                <Button className="button-blue" onClick={handleOpenModalNewNote}>Criar Nova</Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        open={openModalTarefa}
        onClose={handleCloseModalTarefa}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 1000,
          },
        }}
      >
        <Fade in={openModalTarefa}>
          <div className="modal-note">
            <div className="container-modal">
              <ClearIcon className="button-close" onClick={handleCloseModalTarefa} />
              <h3>Próxima tarefa</h3>
              <div className="flex flex-row justify-between align-center">
                <h5>Tipo:</h5>
                <h6>Marcar Reunião Remota</h6>
              </div>
              <div className="flex flex-row justify-between align-center">
                <h5>Data:</h5>
                <h6>16/05/2023</h6>
              </div>
              <div className="flex flex-row justify-between align-center">
                <h5>Horário</h5>
                <h6>10:45:12</h6>
              </div>
              <div className="">
                <h5 id="title-assunto">Assunto:</h5>
                <h6>Apresentação de proposta para lorem ipsum dolor sit amet consec tetur sit ut viverra</h6>
              </div>
              <div className="flex flex-row justify-between aling-center gap-10">
                <Button className="button-primary" onClick={handleOpenModalNote}>Ver Anotações</Button>
                <Button className="button-blue" onClick={handleOpenModalNewNote}>Criar Nova</Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  )
}
