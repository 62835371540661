import React from 'react';
import { Tooltip } from 'antd';

interface Props {
    children?: React.ReactNode;
    title: string;
    stagioIndex: number;
    index: number;
}

const TooltipStagio: React.FunctionComponent<Props> = ({ children, title, stagioIndex, index }: Props) => {
    return (
        <Tooltip title={title} color={index <= stagioIndex ? '#304FFE' : '#ECEFF1'}>
            <span>{children}</span>
        </Tooltip>
    );
}

export default TooltipStagio;