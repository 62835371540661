import {
	ILeadTarefaTipoCleanDTO,
	ILeadTarefaTipoDTO,
	ILeadTarefaTipoFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadTarefaTipoService = () => {
	const resourceURL = 'crm/lead-tarefa-tipo';

	return {
		...baseFindList<ILeadTarefaTipoCleanDTO, ILeadTarefaTipoDTO, ILeadTarefaTipoFullDTO>(resourceURL),
	};
};
