import { ILeadAnotacaoCleanDTO, ILeadAnotacaoDTO, ILeadAnotacaoFullDTO } from "../models/happy-code-api.model";
import { baseCreate } from "./base/base-create.service";

export const useLeadAnotacaoService = () => {

  const resourceURL = 'crm/lead-anotacao';

  return {
    ...baseCreate<ILeadAnotacaoCleanDTO, ILeadAnotacaoDTO, ILeadAnotacaoFullDTO>(resourceURL),
  };

}
