import Table, { ColumnsType } from "antd/es/table";
import { ILeadProps } from "../Modal/adicionarnovasleads";

const columns: ColumnsType<ILeadProps> = [
    {
        title: 'NOME',
        dataIndex: 'NOME',
        key: 'NOME'
    },
    {
        title: 'EMAIL',
        dataIndex: 'EMAIL',
        key: 'EMAIL'
    },
    {
        title: 'TELEFONE',
        dataIndex: 'TELEFONE',
        key: 'TELEFONE'
    },
    {
        title: 'QUALIFICAÇÃO',
        dataIndex: 'QUALIFICACAO',
        key: 'QUALIFICACAO'
    },
    {
        title: 'ETAPA FUNIL',
        dataIndex: 'ETAPA_FUNIL',
        key: 'ETAPA_FUNIL'
    },
    {
        title: 'CAMPANHA 3K',
        dataIndex: 'CAMPANHA_3K',
        key: 'CAMPANHA_3K'
    },
    {
        title: 'STATUS',
        dataIndex: 'STATUS',
        key: 'STATUS'
    },
];

export const PlanilhaLeads = (data: any) => {

    let leadsOk = 0;
    let leadsErro = 0;

    data.data.map((leads: ILeadProps) => {
        if(leads.STATUS == "OK"){
            leadsOk = leadsOk += 1;
        }

        if(leads.STATUS == "ERROR"){
            leadsErro = leadsErro += 1;
        }
    })

    return (
        <>
            <div>
                <Table dataSource={data.data} columns={columns} style={{ width: "100%" }} />
                <div style={{position: 'relative', left: 530, width: 300, bottom: 40}}>
                <span style={{marginRight: 10}}>Leads  Corretos: {leadsOk}</span>
                <span>Leads com Erro: {leadsErro}</span>
                </div>
            </div>
        </>
    )

}
